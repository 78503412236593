const navigation = {
    init() {
        this.events();
    },
    events() {
        /**
         * Execute events
         */
        this.executeNav();
    },    
    executeNav() {
       jQuery('.toggle').on('click', function(){
           var toggle = jQuery(this).attr('data-toggle');
           if(toggle === 'close'){
                jQuery(this).attr('data-toggle', 'open');
                jQuery('.mobile').attr('data-toggle', 'open');
           } else {
                jQuery(this).attr('data-toggle', 'close');
                jQuery('.mobile').attr('data-toggle', 'close');
           }
       });
    },
};
jQuery(() => {
    navigation.init();
});